import { ref } from '@vue/composition-api'
import { apolloClient as apollo } from '@/api/graphql/apollo'

export const invoicesToPostOrVoid = ref([])
export function ResetInvoices () {
  invoicesToPostOrVoid.value.splice(0, invoicesToPostOrVoid.value.length)
}

export function UsePrepaymentVoiding () {
  const canVoid = params => {
    return params.api.getSelectedNodes().every(n => {
      return n.data.status.id < 10 // TODO
    })
  }

  const menuItem = (params, callback) => {
    return {
      name: 'VOID',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">v</span>',
      action: () => {
        prepareToVoidPrepayments({ params: params }, callback)
      },
      cssClasses: ['negativeAction']
    }
  }

  function prepareToVoidPrepayments ({ params }, callback) {
    for (const node of params.api.getSelectedNodes()) {
      invoicesToPostOrVoid.value.push(node.data.id)
    }
    invoicesToPostOrVoid.value.push('hello', '123')

    callback()
  }

  function voidPrepayments (query, variables) {
    apollo.mutate({
      mutation: query,
      variables: variables
    })
  }

  return {
    canVoid,
    menuItem,
    prepareToVoidPrepayments,
    voidPrepayments
  }
}

export function UsePosting () {
  const menuItem = (params, callback) => {
    return {
      name: 'Mark as Posted',
      shortcut: '<i class="fal fa-keyboard pr-1"></i><b>:</b><span class="ml-2 kb-shortcut">p</span>',
      action: () => {
        prepareToPostTransactions({ params: params }, callback)
      },
      disabled: false, // canPost
      cssClasses: ['positiveAction']
    }
  }

  function prepareToPostTransactions ({ params }, callback) {
    for (const node of params.api.getSelectedNodes()) {
      invoicesToPostOrVoid.value.push(node.data.id)
    }
    invoicesToPostOrVoid.value.push('oh man', '456')

    callback()
  }

  return {
    menuItem,
    prepareToPostTransactions
  }
}
