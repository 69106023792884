import { reactive, computed } from '@vue/composition-api'

/**
 * Returns variables for re-using a dialog component with the ability to use
 * dialog.component for dynamic components
 * @param {Array<{type: String, component: String}>} list
 * @return {{resetDialog: resetDialog, dialog: *}}
 * @constructor
 */
export function UseDialog (list) {
  const dialog = reactive({
    title: '',
    show: computed(() => dialog.type !== ''),
    type: '',
    component: computed(() => {
      for (const item of list) {
        if (dialog.type === item.type) {
          return item.component
        }
      }
      return ''
    })
  })

  /**
   * Resets the dialog and then performs a callback for custom logic in the component it is used in
   * @param callback
   */
  function resetDialog (callback) {
    dialog.title = ''
    dialog.type = ''
    callback()
  }

  function prepareDialog (title, type) {
    dialog.title = title
    dialog.type = type
  }

  return {
    dialog,
    resetDialog,
    prepareDialog
  }
}
