




























































import { UseAgGridReady } from '@/lib/agGridColumns'
import tabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import gridWrapper from '@/components/wrappers/gridWrapper.vue'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { UseDialog } from '@/lib/componentLogic'
import { reactive, defineComponent } from '@vue/composition-api'
import { invoicesToPostOrVoid as invoices, UsePosting, UsePrepaymentVoiding } from '@/lib/accountingLogic'
import { CellKeyDownEvent, ColDef, GetContextMenuItemsParams } from 'ag-grid-community'
import { BooleanCellRenderer } from '../../lib/agGridColumnConfiguration'
import { GridKbShortCut } from '@/models/ExtraAgGridRelatedModels'
import { QueryFilter } from '@/models/generated/graphql/ErpBackend.ts'

export default defineComponent({
  name: 'ArPrepayments',
  components: {
    'tab-bar': tabBarWrapper,
    'grid-wrapper': gridWrapper
  },
  setup () {
    // @ts-ignore
    const { dialog, resetDialog, prepareDialog } = new UseDialog([])
    const invoicesToPostOrVoid = invoices
    const { menuItem: voidMenuItem, prepareToVoidPrepayments, voidPrepayments } = UsePrepaymentVoiding()
    const { menuItem: postMenuItem, prepareToPostTransactions } = UsePosting()
    const { gridApi, gridReady } = UseAgGridReady()

    const query = ''
    const filters: QueryFilter[] = []
    const columns: ColDef[] = [
      { headerName: 'Client Name', field: 'client.name', colId: 'clientName' },
      { headerName: 'Client Code', field: 'client.short_name', colId: 'clientShortName' },
      { headerName: 'ST', field: 'attached_sale.id', colId: 'st' },
      { headerName: 'ST Status', field: 'attached_sale.status.status', colId: 'pt' },
      { headerName: 'Date', field: 'payment_date', colId: 'paymentDate', type: 'date' },
      { headerName: 'Amount', field: 'amount', colId: 'amount', type: 'money' },
      { headerName: 'Type', field: 'type', colId: 'type' },
      { headerName: 'Check #', field: 'check_number', colId: 'checkNumber' },
      { headerName: 'Reference', field: 'reference', colId: 'reference' },
      { headerName: 'Posted', field: 'posted', colId: 'posted', cellRenderer: BooleanCellRenderer },
      { headerName: 'Voided', field: 'voided', colId: 'voided', cellRenderer: BooleanCellRenderer },
      // { headerName: 'Void Comments', field: 'void_comments', colId: 'voidComments' }, // put this in the comments thing
      { headerName: 'REP', field: 'attached_sale.rep.initials', colId: 'stRepInitials' },
      { headerName: 'Cash Account', field: 'account.number', colId: 'accountNumber' },
      { headerName: 'Currency', field: 'currency', colId: 'currency' },
      { headerName: 'Journal Code', field: 'attached_journal.code', colId: 'attachedJournalCode' }
    ]

    function localResetDialog (): void {
      resetDialog(() => invoicesToPostOrVoid.value.splice(0, invoicesToPostOrVoid.value.length))
    }

    function setConfirmDialogCallback (voidOrPost: string) {
      dialog.title = `${voidOrPost} Transaction Confirmation`
      dialog.type = 'post/void'
    }

    function contextMenuItems (params: GetContextMenuItemsParams) {
      const contextMenu = []

      contextMenu.push(
        voidMenuItem(params, () => setConfirmDialogCallback('VOID')),
        postMenuItem(params, () => setConfirmDialogCallback('POST'))
      )
      contextMenu.push('copy', 'export')
      return contextMenu
    }

    const masterGridKbShortcuts: GridKbShortCut[] = [
      {
        key: 'v',
        handler: (params: CellKeyDownEvent) => prepareToVoidPrepayments({ params: params }, setConfirmDialogCallback('VOID')),
        rules: () => true,
        invalidMessage: () => ''
      },
      {
        key: 'p',
        handler: (params: CellKeyDownEvent) => prepareToPostTransactions({ params: params }, setConfirmDialogCallback('POST')),
        rules: () => true,
        invalidMessage: () => ''
      }
    ]

    const result = reactive({
      success: false,
      fail: false
    })

    const loading = reactive({
      add: false,
      void: false
    })

    return {
      query,
      filters,
      columns,
      invoicesToPostOrVoid,
      voidPrepayments,
      contextMenuItems,
      dialog,
      resetDialog: localResetDialog,
      GridKbEventHandler,
      masterGridKbShortcuts,
      prepareDialog,
      gridReady,
      gridApi,
      result,
      loading
    }
  }
})
